import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faGlobe, faUserGear } from '@fortawesome/free-solid-svg-icons'

const SupportFragorSvarPage = () => {
	return (
		<Layout pageTitle="Frågor & svar">

            <p>Frågor, tips, problem eller vill skaffa Brfify? Varmt välkommen att kontakta <u><a href="mailto:hej@brfify.se">hej@brfify.se</a></u>.</p>


            <h2>Hur skaffar jag Brfify?</h2>
            <p>Som boende i en bostadsrättsförening eller hyresfastighet som använder Brfify får du en inbjudan med alla instruktioner hur du kommer igång med Brfify-appen. Du kan även om din e-postadress redan finns registerad logga in genom att få en engångskod via e-post.</p>
            <p>Styrelser och fastighetsägare som önskar ansluta sig till Brfify är varmt välkommen att kontakta <a href="mailto:hej@brfify.se">hej@brfify.se</a> eller göra intresseanmälan <Link to="/skaffa">här</Link>.</p>


            <h2>Var finns apparna?</h2>
            <p>Appen "Brfify" finns i Google Play Store och Apple App Store, läs mer <Link to="/support/app">här</Link>.</p>


            <h2>Kostar Brfify något?</h2>
            <p>För boende kostar det inget att använda Brfify. Aktuella priser för fastighetsägare finns på sidan <Link to="/pris">Pris</Link>.</p>


            <h2>Kan jag vara inloggad i flera enheter?</h2>
            <p>Ja, exempelvis genom att logga in via att få en engångskod till sin e-postadress.</p>


            <h2>Är Brfify endast för bostadsrättsföreningar?</h2>
            <p>Nej, även små till medelstora fastighetsägare med hyresrätter är varmt välkomna att använda Brfify.</p>


            <h2>Vilka står bakom Brfify?</h2>
            <p>Brfify är utvecklat och tillhandahållas av "Aktiebolag Erik Harlefelt" med organisationsnummer 559389-7241. Läs mer <a href="https://iterik.se" target="_blank">här</a>.</p>
            
		</Layout>
	)
}

export default SupportFragorSvarPage